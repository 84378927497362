import { encodeBase64 } from 'crypto-helpers'
import config from 'config'


type ImageSrcProps = {
  src: string
  bgColor?: string
  width?: number
  height?: number
  quality?: number
  format?: string
  multiplier?: number
  customParams?: string
}

const getDeviceResizeValue = (value: number, customMultiplier?: number) => {
  if (customMultiplier) {
    return Math.ceil(value * customMultiplier)
  }
  const multiplier = (typeof window !== 'undefined' && !window.__DEVICE__?.isBot ? window.devicePixelRatio : 1) || 1
  return Math.ceil(value * multiplier)
}

const getImgProxyUrl = (props: ImageSrcProps): string => {
  const { src, bgColor, quality, width, height, multiplier, format, customParams } = props

  const parts = []
  if (width || height) {
    parts.push('rt:fill', width ? `w:${getDeviceResizeValue(width, multiplier)}` : `h:${getDeviceResizeValue(height, multiplier)}`)
  }

  if (customParams) {
    parts.push(customParams)
  }

  if (quality) {
    parts.push(`fq:jpg:${quality}:avif:${Math.ceil(quality * 65 / 80)}:webp:${quality}`)
  }

  if (bgColor) {
    parts.push(`bg:${bgColor.replace('#', '')}`, `f:jpg`)
  }

  if (format) {
    parts.push(`f:${format}`)
  }

  // we serve files from s3 directly
  parts.push(encodeBase64(src.replace('https://cdn.scentbird.com/', '')))

  const url = `/${parts.join('/')}`

  // we use unsigned urls, because we need to do it on the client side and in sync manner
  return `${config.imgproxyUrl}/_${url}`
}

export const getZinaImage = (props: ImageSrcProps): string => {
  const { src, bgColor, quality, width, height, multiplier } = props

  if (!src) {
    return src
  }

  // imgproxy is enabled
  if (src.charAt(0) !== '/') {
    return getImgProxyUrl(props)
  }

  let finalSrc = src
  const params = []

  if (width || height) {
    params.push(width ? `w=${getDeviceResizeValue(width, multiplier)}` : `h=${getDeviceResizeValue(height, multiplier)}`)
  }

  if (quality) {
    params.push(`qlty=${quality}`)
  }

  if (bgColor) {
    params.push(`bgcolor=${bgColor.replace('#', '')}`)
  }

  if (!params.length) {
    return finalSrc
  }

  return `${finalSrc}?${params.join('&')}`
}
