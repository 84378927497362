import React from 'react'
import cx from 'classnames'

import Image from '../../../Image/Image'
import VideoPreviewIcon, { type VideoPreviewIconProps } from './VideoPreviewIcon'


export type PreviewProps = {
  className?: string
  bgImage: string
  previewStyle?: VideoPreviewIconProps['style']
  onClick?: () => void
  onMouseOver?: () => void
}

const Preview: React.FC<PreviewProps> = (props) => {
  const { className, bgImage, previewStyle, onClick, onMouseOver } = props
  return (
    <div
      className={cx(className, 'absolute left-0 top-0 size-full cursor-pointer bg-cover bg-center outline-none')}
      role="button"
      tabIndex={0}
      onClick={onClick}
      onMouseOver={onMouseOver}
    >
      <Image fill src={bgImage} alt="Poster" />
      <VideoPreviewIcon style={previewStyle} />
    </div>
  )
}

export default Preview
