import React from 'react'
import cx from 'classnames'

import Text, { type TextColor, type TextStyle } from '../Text/Text'
import Icon, { type IconColor } from '../Icon/Icon'
import type { IconName } from '../Icon/images'


export const colorToClassName = {
  'gold-50': 'border-solid-gold-50',
} as const

export type Color = keyof typeof colorToClassName

export const activeColorToClassName: Record<Color, string> = {
  'gold-50': 'bg-gold-50',
}

export const colorToTextColor: Record<Color, TextColor> = {
  'gold-50': 'black',
}

export const activeColorToTextColor: Record<Color, TextColor> = {
  'gold-50': 'white',
}

export type ChipProps = {
  active?: boolean
  className?: string
  textClassName?: string
  color: Color
  icon?: IconName
  iconColor?: IconColor
  label: Intl.Message | string
  labelStyle?: TextStyle
  onClick?: () => void
  'data-testid'?: string
}

const Chip: React.FunctionComponent<ChipProps> = (props) => {
  const { active, className, textClassName = '', color, icon, iconColor, label, labelStyle = 'p2', 'data-testid': dataTestId, onClick } = props

  const rootClassName = cx(
    'inline-block select-none whitespace-nowrap rounded',
    className || 'px-16 py-8',
    colorToClassName[color],
    active && activeColorToClassName[color],
    onClick && 'cursor-pointer',
    icon && 'flex items-center gap-4'
  )

  return (
    <div className={rootClassName} role="button" onClick={onClick}>
      <Text
        message={label}
        color={active ? activeColorToTextColor['gold-50'] : colorToTextColor['gold-50']}
        style={labelStyle}
        className={textClassName}
        data-testid={dataTestId}
      />
      {icon && <Icon name={icon} color={iconColor} />}
    </div>
  )
}

export default React.memo(Chip)
