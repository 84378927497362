import React from 'react'
import cx from 'classnames'
import { Message } from 'intl'
import { getGlobalHtmlAttrs, type GlobalHTMLAttrs } from 'helpers/getters'

import s from './Text.module.css'


export const styleToClassName = {
  'h1': 'text-h1',
  'h2': 'text-h2',
  'h3': 'text-h3',
  'h4': 'text-h4',
  'h5': 'text-h5',
  'h6': 'text-h6',
  'h7': 'text-h7',
  'h8': 'text-h8',
  'h9': 'text-h9',
  'sh1': 'text-sh1',
  'sh2': 'text-sh2',
  'sh3': 'text-sh3',
  'sh4': 'text-sh4',
  'sh5': 'text-sh5',
  'sh6': 'text-sh6',
  'p1': 'text-p1',
  'p2': 'text-p2',
  'p3': 'text-p3',
  'p4': 'text-p4',
  'p5': 'text-p5',
  'p6': 'text-p6',
  'eye1': 'text-eye1',
  'eye2': 'text-eye2',
  'eye3': 'text-eye3',
  't1': 'text-t1',
  't2': 'text-t2',
  't3': 'text-t3',
  't4': 'text-t4',
  't5': 'text-t5',
  't6': 'text-t6',
  't7': 'text-t7',
} as const

export const colorToClassName = {
  'gray-30': 'text-gray-30',
  'gray-40': 'text-gray-40',
  'gray-50': 'text-gray-50',
  'gray-70': 'text-gray-70',
  'gray-80': 'text-gray-80',
  'gray-90': 'text-gray-90',
  'white': 'text-white',
  'black': 'text-black',
  'gold-30': 'text-gold-30',
  'gold-50': 'text-gold-50',
  'gold-70': 'text-gold-70',
  'gold-80': 'text-gold-80',
  'red': 'text-red',
  'pink': 'text-pink',
  'dark-pink': 'text-dark-pink',
  'faded-pink': 'text-faded-pink',
  'dark-red': 'text-dark-red',
  'light-beige': 'text-light-beige',
  'orange': 'text-orange',
} as const

export const alignToClassName = {
  'left': 'text-left',
  'right': 'text-right',
  'center': 'text-center',
} as const

export type TextStyle = keyof typeof styleToClassName
export type TextColor = keyof typeof colorToClassName
export type TextAlign = keyof typeof alignToClassName

export type TextBaseProps = {
  children?: React.ReactNode
  id?: string
  className?: string
  textClassName?: string
  message?: Intl.Message | string
  tag?: string
  messageTag?: string
  style: TextStyle
  color?: TextColor
  align?: TextAlign
  html?: boolean
  role?: GlobalHTMLAttrs['role']
  tabIndex?: number
  withInnerLinksStyling?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

const Text = React.forwardRef<HTMLElement, TextBaseProps & GlobalHTMLAttrs>((props, ref) => {
  let {
    children, id, className, tag = 'div', messageTag, message,
    style, color, align, html, withInnerLinksStyling, textClassName, onClick, ...otherProps
  } = props

  if (onClick && tag !== 'button') {
    console.error('You can\'t use "onClick" without passing tag === "button". Create components ADA friendly!')
  }

  const rootClassName = cx(
    s.text,
    className,
    textClassName,
    styleToClassName[style],
    colorToClassName[color],
    alignToClassName[align],
    withInnerLinksStyling || html && withInnerLinksStyling !== false && s.linkStyle
  )

  const htmlAttrs = getGlobalHtmlAttrs(otherProps)

  if (message) {
    children = <Message value={message} tag={messageTag} html={html} />
  }

  return React.createElement(tag, {
    ...htmlAttrs,
    ref,
    id,
    className: rootClassName,
    onClick,
  }, children)
})

Text.displayName = 'Text'


export default Text
