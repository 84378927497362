import React from 'react'
import { useDevice } from 'device'
import { deviceVm } from 'helpers'

import NativeImage from './components/NativeImage/NativeImage'
import NativeImageWrapper from './components/NativeImageWrapper/NativeImageWrapper'


export { getZinaImage } from './components/NativeImage/util/getZinaImage'
export { getImageDataFromStrapi } from './components/NativeImage/util/getImageDataFromStrapi'

export type ImageProps = Image.Props

const Image: React.FunctionComponent<ImageProps> = (props) => {
  const { isBot, isMobile, isTablet } = useDevice()

  // if it's a real client, and we don't have provided size properties,
  // we use a wrapper which provides sizes measurements
  if (!isBot && !props.sizes && !props.width && !props.height && !props.remWidth) {
    let src: string
    let srcWithSize = false

    if (typeof props.src === 'string') {
      src = props.src
    }
    else if (props.src) {
      src = props.src.src
      srcWithSize = Boolean(props.src.width && props.src.height)
    }

    if (src && !src.endsWith('.svg') && !srcWithSize) {
      return (
        <NativeImageWrapper {...props} />
      )
    }
  }

  // little helper to remove code duplication if you need to set specific size of the image
  let sizes = undefined
  let width = undefined
  const { remWidth } = props

  if (remWidth) {
    if (isMobile && !isTablet) {
      sizes = deviceVm(remWidth)
    }
    else if (props.fill) {
      sizes = `${remWidth}px`
    }
    else {
      width = Number(remWidth)
    }
  }
  else {
    sizes = props.sizes
    width = props.width
  }

  return (
    <NativeImage
      {...props}
      sizes={sizes}
      width={width}
      withLoader={isBot ? false : props.withLoader}
    />
  )
}


export default Image
