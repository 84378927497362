import { twcx } from 'helpers'
import { getZinaImage } from './getZinaImage'
import { getWidths } from './getWidths'


// eslint-disable-next-line stylistic/max-len
const placeholderSvg = `<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'><path stroke='#DEDEDE' stroke-width='2' d='M19 10a9 9 0 11-9-9'><animateTransform attributeName='transform' type='rotate' from='0 10 10' to='360 10 10' dur='0.6s' repeatCount='indefinite' /></path></svg>`

const placeholderStyles = {
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url("data:image/svg+xml;charset=utf-8,${encodeURIComponent(placeholderSvg)}")`,
  backgroundSize: '20rem',
  backgroundPosition: '50% 50%',
}

export const getImageProps = (props: Image.Props, state: { blurComplete: boolean, showAltText: boolean }) => {
  const {
    className,
    // can be src or object from a loader
    src: original,
    width: customWidth,
    height: customHeight,
    alt = '',
    sizes,
    bgColor,
    quality,
    format,
    fill = false,
    priority = false,
    lazy = true,
    style,
    withLoader = true,
    remWidth, // just to remove it
    unoptimized: customUnoptimized = false,
    customParams,
    ...rest
  } = props

  let unoptimized = Boolean(customUnoptimized)
  let isLazy = !priority && lazy

  // original sizes to provide to img tag by default
  let width: number
  let height: number
  let src: string

  if (original) {
    if (typeof original === 'string') {
      src = original

      if (!fill) {
        width = customWidth
        height = customHeight
      }
    }
    else if (typeof original === 'object') {
      src = original.src

      // we don't allow fill with width and height
      if (!fill) {
        // override
        if (!customWidth && !customHeight) {
          width = original.width
          height = original.height
        }
        // keep aspect ratio
        else if (customWidth) {
          width = customWidth
          height = Math.ceil(original.height / original.width * customWidth)
        }
        // keep aspect ratio
        else if (customHeight) {
          width = Math.ceil(original.width / original.height * customHeight)
          height = customHeight
        }
      }
    }
  }

  if (!src || src.startsWith('data:') || src.startsWith('blob:')) {
    unoptimized = true
    isLazy = false
  }
  else if (src.endsWith('.svg') || !src.startsWith('https://cdn.scentbird.com/')) {
    unoptimized = true
  }

  const result = {
    ...rest,
    className: twcx(
      fill && 'absolute inset-0 size-full object-cover object-center',
      !state.showAltText && 'color-transparent',
      className
    ),
    srcSet: '',
    src,
    sizes: sizes,
    width,
    height,
    alt,
    loading: isLazy ? 'lazy' as const : undefined,
    decoding: isLazy ? 'async' as const : undefined,
    fetchPriority: priority ? 'high' as const : undefined,
    withLoader,
    style: {
      ...style,
      ...(withLoader && !state.blurComplete ? placeholderStyles : {}),
    },
  }

  if (fill && !unoptimized) {
    result['data-fill'] = '1'
  }

  if (unoptimized) {
    return result
  }

  const { widths, kind } = getWidths(width, sizes)

  if (!sizes && kind === 'w') {
    result.sizes = '100vw'
  }

  result.srcSet = widths.map((width, index) => {
    const url = getZinaImage({
      src,
      width,
      bgColor,
      quality,
      format,
      multiplier: 1,
      customParams,
    })
    // save the last image as a default src
    result.src = url
    return `${url} ${kind === 'w' ? width : index + 1}${kind}`
  }).join(', ')

  return result
}
