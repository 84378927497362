export default {
  selectableLabel: {
    en: 'Select rating',
  },
  readonlyLabel: {
    en: '{value} out of 5',
  },
  item: {
    en: '{value, plural, one {# star} other {# stars}}',
  },
  emptyHint: {
    en: 'Tap to rate',
  },
  valueHint: {
    5: {
      en: 'Amazing',
    },
    4: {
      en: 'Very good',
    },
    3: {
      en: 'Pretty good',
    },
    2: {
      en: 'Needs improvement',
    },
    1: {
      en: 'Not for me',
    },
  },
}
