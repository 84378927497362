import React, { useCallback, useEffect, useState } from 'react'
import type { EmblaCarouselType } from 'embla-carousel'
import { useIntl } from 'intl'
import { twcx } from 'helpers'
import { Icon } from 'components/dataDisplay'

import messages from './messages'


type ButtonsProps = {
  emblaApi: EmblaCarouselType
  className?: string
  buttonClassName?: string
  prevClassName?: string
  nextClassName?: string
}

const Buttons: React.FC<ButtonsProps> = (props) => {
  const { emblaApi, className, buttonClassName, prevClassName, nextClassName } = props

  const intl = useIntl()

  const [ prevBtnDisabled, setPrevBtnDisabled ] = useState(true)
  const [ nextBtnDisabled, setNextBtnDisabled ] = useState(true)

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollPrev()
  }, [ emblaApi ])

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollNext()
  }, [ emblaApi ])

  useEffect(() => {
    if (!emblaApi) return

    const onSelect = (emblaApi: EmblaCarouselType) => {
      setPrevBtnDisabled(!emblaApi.canScrollPrev())
      setNextBtnDisabled(!emblaApi.canScrollNext())
    }

    onSelect(emblaApi)
    emblaApi.on('reInit', onSelect).on('select', onSelect)

    return () => {
      if (!emblaApi) {
        return
      }

      emblaApi.off('reInit', onSelect).off('select', onSelect)
    }
  }, [ emblaApi ])

  return (
    <div className={twcx(className)}>
      <button
        type="button"
        className={twcx(buttonClassName, prevClassName)}
        onClick={onPrevButtonClick}
        disabled={prevBtnDisabled}
        aria-label={intl.formatMessage(messages.prev)}
      >
        <Icon name="32/arrow-left-in-circle" />
      </button>
      <button
        type="button"
        className={twcx(buttonClassName, nextClassName)}
        onClick={onNextButtonClick}
        disabled={nextBtnDisabled}
        aria-label={intl.formatMessage(messages.next)}
      >
        <Icon name="32/arrow-right-in-circle" />
      </button>
    </div>
  )
}

export default Buttons
