import React, { useCallback } from 'react'
import { openModal, preloadModal } from 'modals'

import Preview from '../Preview/Preview'


type PlayInModalProps = {
  className?: string
  url: string
  type: string
  preview?: string
  previewStyle?: 'default' | 'drift' | 'gold30' | 'withoutIcon'
  onPlay?: () => void
  onEnded?: () => void
}

const PlayInModal: React.FC<PlayInModalProps> = (props) => {
  const { className, url, type, preview, previewStyle, onPlay, onEnded } = props

  const handlePlaceholderClick = useCallback(() => {
    if (typeof onPlay === 'function') {
      onPlay()
    }

    openModal('videoModal', { type: type as any, url, onEnded })
  }, [ onPlay, url, type, onEnded ])

  const handlePlaceholderMouseOver = useCallback(() => {
    void preloadModal('videoModal')
  }, [])

  return (
    <Preview
      className={className}
      bgImage={preview}
      previewStyle={previewStyle}
      onClick={handlePlaceholderClick}
      onMouseOver={handlePlaceholderMouseOver}
    />
  )
}


export default PlayInModal
