import React from 'react'
import cx from 'classnames'

import { Text } from 'components/dataDisplay'


export const colors = [ 'black', 'pink' ] as const

export type Color = typeof colors[number]

type OfferLabelProps = {
  className?: string
  title: string | Intl.Message
  bgColor?: Color
  'data-testid'?: string
}

const OfferLabel: React.FunctionComponent<OfferLabelProps> = (props) => {
  const { className, title, bgColor = 'black', 'data-testid': dataTestId } = props

  const rootClassName = cx(className, 'inline-block rounded-2 px-8 py-4', {
    'bg-black text-white': bgColor === 'black',
    'bg-pink text-black': bgColor === 'pink',
  })

  return (
    <Text className={rootClassName} message={title} style="p5" data-testid={dataTestId} />
  )
}


export default OfferLabel
