import React, { useRef } from 'react'
import cx from 'classnames'
import logger from 'logger'
import loadable from '@loadable/component'

import { AspectContainer } from 'components/layout'

import { type MP4Props } from './components/MP4/MP4'
import { type YoutubePlayerProps } from './components/YoutubePlayer/YoutubePlayer'
import { type VimeoPlayerProps } from './components/VimeoPlayer/VimeoPlayer'


type CommonProps = {
  className?: string
  videoClassName?: string
  type: 'mp4' | 'youtube' | 'vimeo'
  fit?: 'cover' | 'contain'
  aspect?: number
  withBackground?: boolean
  playInModal?: boolean
  'data-testid'?: string
}

export type VideoProps = CommonProps & MP4Props & YoutubePlayerProps & VimeoPlayerProps

const Video: React.FunctionComponent<VideoProps> = (props) => {
  const { className, videoClassName, type, aspect = 100 / 56.25, fit, withBackground = true, 'data-testid': dataTestId, ...rest } = props

  const loggerRef = useRef(false)
  let Component = null

  const playInModal = rest.playInModal && rest.preview && rest.preview !== rest.url

  if (playInModal) {
    Component = loadable(() => import('./components/PlayInModal/PlayInModal'))
  }
  else {
    switch (type?.toLowerCase()) {
      case 'mp4':
        Component = loadable(() => import('./components/MP4/MP4'))
        break
      case 'youtube':
        Component = loadable(() => import('./components/YoutubePlayer/YoutubePlayer'))
        break
      case 'vimeo':
        Component = loadable(() => import('./components/VimeoPlayer/VimeoPlayer'))
        break
      default:
        if (!loggerRef.current) {
          loggerRef.current = true
          logger.error({ props }, 'Video type is not recognized')
        }
        break
    }
  }

  if (Component) {
    return (
      <AspectContainer className={cx(className, withBackground && 'bg-black')} aspect={aspect} fit={fit} data-testid={dataTestId}>
        {
          React.createElement(Component, { ...rest, className: videoClassName, type })
        }
      </AspectContainer>
    )
  }

  return null
}


export default Video
