const amount = 51
const diff = Math.floor(360 / amount)
const base = { h: 28, s: 39, l: 64 } // #c7a17f from the figma
const magicNumber = 96 // just a shift to set up base color of default initials "SB" from blue to dusty-pink :)

const createColors = () => {
  let hue = base.h
  let result = []

  for (let i = 0; i <= amount; i++) {
    // if (excludeCold && hue > 70 && hue < 270) {
    //   hue = 270
    // }

    if (hue > 360) {
      hue = hue - 360
    }
    else if (hue === 360) {
      hue = 359
    }
    else if (hue <= 0) {
      hue = 1
    }

    result[i] = (hue)

    hue = hue + diff
  }

  return result.map((h) => `hsl(${h}, ${base.s}%, ${base.l}%)`)
}

const colorList = createColors()

const getBackgroundColor = (initials: string) => {
  if (!initials) {
    return colorList[0]
  }

  let charsCode = (initials.charCodeAt(0) || 0) + (initials.charCodeAt(1) || 0) + magicNumber

  // let take 'A' (charCode = 65) as first item which will have index = 0
  // so calc index sift for the char (it can be greater than amount of colors so calc remainder from it)
  const index = charsCode % 65 % amount

  return colorList[index]
}


export default getBackgroundColor
