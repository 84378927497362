// if there is no width specified, we apply default set of widths, thinking that image size is 100vw
const deviceSizes = [ 640, 750, 828, 1080, 1200, 1440, 1920, 2048, 2272, 2880, 3840 ]
// when image has custom sizes property
const imageSizes = [ 64, 96, 128, 256, 384, 480 ]

const allSizes = [ ...imageSizes, ...deviceSizes ].sort((a, b) => a - b)

export const getWidths = (width: number, sizes: string) => {
  // custom size
  if (sizes) {
    // strip all sizes in condition to ignore them
    // e.g. 100vw, (max-width: 200px) 300px will become 100vw, 300px
    const sizesWithoutConditions = sizes.replace(/\(.*?\)/g, '')
    // Find all the "vw" percent sizes used in the sizes prop
    const widthRe = /(^|\s)(\d+)(vw|px)/g
    const pxSizes = []

    for (let match; match = widthRe.exec(sizesWithoutConditions); match) {
      const value = parseInt(match[2])
      if (match[3] === 'px') {
        pxSizes.push(value) // min size
        pxSizes.push(value * 3) // max size
      }
      else {
        pxSizes.push(deviceSizes[0] * value * 0.01)
        pxSizes.push(deviceSizes[deviceSizes.length - 1] * value * 0.01)
      }
    }

    if (pxSizes.length) {
      const smallestSize = Math.min(...pxSizes)
      const biggestSize = Math.max(...pxSizes)

      // try to find first size bigger or equal than required one
      let existingBiggestSize = allSizes[0]
      for (let size of allSizes) {
        existingBiggestSize = size
        if (size >= biggestSize) {
          break
        }
      }

      return {
        widths: allSizes.filter((size) => size >= smallestSize && size <= existingBiggestSize),
        kind: 'w',
      }
    }

    return {
      widths: allSizes,
      kind: 'w',
    }
  }

  // no specified width (fill mode), just use predefined sizes
  if (typeof width !== 'number') {
    return {
      widths: deviceSizes,
      kind: 'w',
    }
  }

  // base on width, we find closes pre-defined image size
  const widths = [
    ...new Set(// > This means that most OLED screens that say they are 3x resolution,
      // > are actually 3x in the green color, but only 1.5x in the red and
      // > blue colors. Showing a 3x resolution image in the app vs a 2x
      // > resolution image will be visually the same, though the 3x image
      // > takes significantly more data. Even true 3x resolution screens are
      // > wasteful as the human eye cannot see that level of detail without
      // > something like a magnifying glass.
      // https://blog.twitter.com/engineering/en_us/topics/infrastructure/2019/capping-image-fidelity-on-ultra-high-resolution-devices.html
      [
        width,
        width * 2, /*, width * 3*/
      ].map((w) => allSizes.find((s) => s >= w) || allSizes[allSizes.length - 1])),
  ]
  return {
    widths,
    kind: 'x',
  }
}
