import React from 'react'
import cx from 'classnames'
import { Text } from 'components/dataDisplay'


const variantToClassName = {
  'gold': 'bg-exclusive-gold-gradient',
  'gold-50': 'bg-gold-50',
  'pink': 'bg-faded-pink',
  'dark-pink': 'bg-dark-pink',
} as const

type Variant = keyof typeof variantToClassName

type ExclusiveLabelProps = {
  labelText: string | Intl.Message
  variant?: Variant
}

const ExclusiveLabel: React.FC<ExclusiveLabelProps> = (props) => {
  const { labelText, variant = 'gold' } = props

  const variantClassName = variantToClassName[variant]

  return (
    <span className={cx(variantClassName, 'ml-16 inline-block px-12 py-4 text-white')}>
      <Text message={labelText} style="eye3" />
    </span>
  )
}

export default ExclusiveLabel
