import React, { forwardRef } from 'react'
import { Svg } from 'svg-provider'

import { twcx } from 'helpers'
import { type GlobalHTMLAttrs } from 'helpers/getters'

// ATTN if you have an error here, run generate:icons script
import icons, { type IconName } from './images'

import s from './Icon.module.css'


export const colors = [
  'white',
  'gray-30',
  'gray-80',
  'black',
  'gold-30',
  'gold-50',
  'gold-70',
  'gold-80',
  'green',
  'red',
  'light-beige',
] as const

export { default as icons } from './images'
export type { IconName } from './images'
export type IconColor = typeof colors[number]

const colorToClassName: Record<IconColor, string> = {
  'white': 'text-white',
  'black': 'text-black',
  'green': 'text-green',
  'red': 'text-red',
  'gold-30': 'text-gold-30',
  'gold-50': 'text-gold-50',
  'gold-70': 'text-gold-70',
  'gold-80': 'text-gold-80',
  'gray-30': 'text-gray-30',
  'gray-80': 'text-gray-80',
  'light-beige': 'text-light-beige',
} as const

const sizeToClassName = {
  16: 'size-s16',
  20: 'size-s20',
  24: 'size-s24',
  32: 'size-s32',
  40: 'size-s40',
  48: 'size-s48',
  64: 'size-s64',
}

type IconProps = GlobalHTMLAttrs & {
  className?: string
  name: IconName
  color?: IconColor
  isInline?: boolean
  alt?: string
}

const Icon = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { className, name, color, isInline, ...rest } = props

  // mock for tests
  if (__TEST__) {
    return (
      <svg width={1} height={1} viewBox="0 0 1 1" />
    )
  }

  if (!icons[name]) {
    console.error(`There is no icon with name "${name}"`)

    return null
  }

  const { src, source, aspect, width, height } = icons[name]

  // we support only square sizes
  const size = width === height ? width : null

  const rootClassName = twcx(s[name], // for custom icons
    sizeToClassName[size],
    colorToClassName[color],
    className
  )

  return (
    <Svg
      ref={ref}
      className={rootClassName}
      src={src}
      source={source}
      width={width}
      height={height}
      aspect={aspect}
      symbolId={name}
      isInline={isInline}
      {...rest}
    />
  )
})

Icon.displayName = 'Icon'


export default Icon
