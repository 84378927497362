import React from 'react'
import { twcx, cx } from 'helpers'

import Image, { type ImageProps } from '../Image/Image'


type BackgroundImageProps = {
  children?: React.ReactNode
  className?: string
  imageClassName?: string
  src: ImageProps['src']
  sizes?: ImageProps['sizes']
  remWidth?: ImageProps['remWidth']
  lazy?: boolean
  quality?: number
  style?: Record<string, any> // for dev purpose only!
}

const BackgroundImage: React.FunctionComponent<BackgroundImageProps> = (props) => {
  const { children, className, imageClassName, src, quality, sizes, remWidth, style, lazy } = props

  return (
    <figure className={twcx('relative z-0', className)} style={style}>
      <Image
        className={cx('-z-1 select-none', imageClassName)}
        fill
        src={src}
        quality={quality}
        sizes={sizes}
        remWidth={remWidth}
        lazy={lazy}
        alt=""
      />
      {children}
    </figure>
  )
}


export default BackgroundImage
