import React, { useCallback } from 'react'
import { twcx } from 'helpers'
import { openModal, preloadModal } from 'modals'

import { buttonMessages } from 'components/inputs'
import { Text, PlayIcon, type PlayIconProps } from 'components/dataDisplay'


type VideoType = 'mp4' | 'youtube' | 'vimeo'
type VideoStyle = 'default' | 'inverted'

export type VideoButtonProps = {
  className?: string
  url: string
  type: VideoType
  title?: Intl.Message
  style?: VideoStyle
  iconStyle?: PlayIconProps['style']
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, extra: { type: VideoType, url: string}) => void
  onEnded?: () => void
}

const VideoButton: React.FunctionComponent<VideoButtonProps> = (props) => {
  const { className, type, url, title = buttonMessages.playVideo, style = 'default', iconStyle = 'drift', onClick, onEnded } = props

  const handleClick = useCallback((event) => {
    if (typeof onClick === 'function') {
      onClick(event, { type, url })
    }

    openModal('videoModal', { type, url, onEnded })
  }, [ type, url, onClick, onEnded ])

  const handleMouseOver = useCallback(() => {
    void preloadModal('videoModal')
  }, [])

  const buttonClassName = twcx(
    'flex items-center rounded-full bg-white py-8 pl-16 pr-12 text-black',
    style === 'inverted' && 'bg-black text-white',
    className
  )

  return (
    <button
      type="button"
      className={buttonClassName}
      onClick={handleClick}
      onMouseOver={handleMouseOver}
    >
      <Text className="mr-6" style="h8" message={title} />
      <PlayIcon size={18} style={iconStyle} />
    </button>
  )
}


export default VideoButton
