export const getImageDataFromStrapi = (image?: { url: string, width?: number, height?: number } | null) => {
  if (!image?.url) {
    return undefined
  }

  return {
    src: image.url,
    width: image.width || undefined,
    height: image.height || undefined,
    aspect: image.height > 0 ? image.width / image.height : undefined,
  }
}
