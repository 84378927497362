import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import type { EmblaCarouselType } from 'embla-carousel'
// eslint-disable-next-line import/no-named-as-default-member
import useEmblaCarousel from 'embla-carousel-react'
import { useDevice } from 'device'
import { twcx, cx } from 'helpers'

import Buttons from './components/Buttons/Buttons'
import Dots from './components/Dots/Dots'


export type CarouselRef = Pick<EmblaCarouselType, 'scrollTo'>

export type CarouselProps = {
  children: React.ReactNode
  className?: string
  viewportClassName?: string
  containerClassName?: string
  buttons?: {
    className?: string
    buttonClassName?: string
    prevClassName?: string
    nextClassName?: string
  }
  dots?: {
    className?: string
    dotClassName?: string
    activeDotClassName?: string
  }
  withButtons?: boolean
  withDots?: boolean
  slidesToScroll?: number | 'auto'
  loop?: boolean // loop carousel
  autoplay?: boolean
  autoplaySpeed?: number
  // events
  onSlideSelect?: (emblaApi: EmblaCarouselType, index: number) => void
  'data-testid'?: string
  dataAttributes?: Record<string, any>
}

const Carousel = forwardRef<CarouselRef, CarouselProps>((props, forwardedRef) => {
  const {
    children,
    className,
    viewportClassName,
    containerClassName,
    buttons,
    dots,
    withButtons = false,
    withDots = false,
    loop = false,
    slidesToScroll = 'auto',
    autoplay = false,
    autoplaySpeed = 2500,
    onSlideSelect,
    'data-testid': dataTestId,
    dataAttributes,
  } = props

  const { isMobile } = useDevice()

  const carouselRef = useRef<HTMLDivElement>(null)

  const [ emblaRef, emblaApi ] = useEmblaCarousel({
    axis: 'x',
    loop,
    slidesToScroll,
    skipSnaps: true,
    watchDrag: (emblaApi, event) => {
      // allow drag for touch events only
      return ('touches' in event)
    },
  })

  useImperativeHandle(forwardedRef, () => ({
    scrollTo: (index: number, jump?: boolean) => {
      emblaApi?.scrollTo(index, jump)
    },
  }))

  useEffect(() => {
    const handler = (emblaApi: EmblaCarouselType) => {
      if (typeof onSlideSelect === 'function') {
        onSlideSelect(emblaApi, emblaApi.selectedScrollSnap())
      }
    }

    if (emblaApi) {
      emblaApi.on('select', handler)
    }
    return () => {
      if (emblaApi) {
        emblaApi.off('select', handler)
      }
    }
  }, [ emblaApi, onSlideSelect ])

  // Autoplay
  useEffect(() => {
    const carouselElement = carouselRef.current

    if (!autoplay || !carouselElement) {
      return
    }

    let autoplayInterval = null

    const start = () => {
      if (autoplayInterval === null) {
        autoplayInterval = setInterval(() => {
          if (emblaApi) {
            emblaApi.scrollNext()
          }
        }, autoplaySpeed)
      }
    }

    const stop = () => {
      if (autoplayInterval !== null) {
        clearInterval(autoplayInterval)
        autoplayInterval = null
      }
    }

    carouselElement.addEventListener('mouseover', stop)
    carouselElement.addEventListener('touchstart', stop)
    carouselElement.addEventListener('mouseout', start)
    carouselElement.addEventListener('touchcancel', start)
    carouselElement.addEventListener('touchend', start)
    start()

    return () => {
      stop()
      carouselElement.removeEventListener('mouseover', stop)
      carouselElement.removeEventListener('touchstart', stop)
      carouselElement.removeEventListener('mouseout', start)
      carouselElement.removeEventListener('touchcancel', start)
      carouselElement.removeEventListener('touchend', start)
    }
  }, [ emblaApi, autoplay, autoplaySpeed ])

  return (
    <div ref={carouselRef} className={twcx('relative select-none', className)} data-testid={dataTestId} {...dataAttributes}>
      <div ref={emblaRef} className={twcx('relative overflow-hidden', viewportClassName)}>
        <div className={twcx('touch-[pay-y_pinch-zoom] grid auto-cols-[100%] grid-flow-col will-change-transform', containerClassName)}>
          {children}
        </div>
      </div>
      {
        Boolean(withButtons) && (
          <Buttons
            emblaApi={emblaApi}
            className={buttons?.className}
            buttonClassName={cx('z-2 disabled:text-gray-30 absolute top-1/2 -translate-y-1/2 cursor-pointer text-black disabled:cursor-default', buttons?.buttonClassName)}
            prevClassName={cx('left-0', buttons?.prevClassName)}
            nextClassName={cx('right-0', buttons?.nextClassName)}
          />
        )
      }
      {
        Boolean(withDots) && (
          <Dots
            emblaApi={emblaApi}
            className={cx(
              'text-gray-30 mobile:min-h-32 desktop:min-h-40 flex flex-wrap items-center justify-center',
              isMobile ? 'min-h-32' : 'min-h-40',
              dots?.className
            )}
            dotClassName={cx(
              'bg-gold-30 mx-4 mt-16 block cursor-pointer rounded-full outline-none',
              isMobile ? 'size-s7 mb-4' : 'size-s8 desktop-hover:bg-black mb-16',
              dots?.dotClassName
            )}
            activeDotClassName={cx('bg-black', dots?.activeDotClassName)}
          />
        )
      }
    </div>
  )
})

Carousel.displayName = 'Carousel'


export default Carousel
