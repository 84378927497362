import React from 'react'
import cx from 'classnames'


export type PlayIconProps = {
  className?: string
  size: 18 | 60 | 72
  style: keyof typeof styleToClasses
}

// [ bgColor, arrowColor ]
const styleToClasses = {
  'default': [ 'text-black', 'text-gold-30' ],
  'drift': [ 'text-black-100', 'text-white' ],
  'gold-30': [ 'text-gold-30', 'text-black' ],
  'gold-70-white': [ 'text-gold-70', 'text-white' ],
}

const PlayIcon: React.FunctionComponent<PlayIconProps> = (props) => {
  const { className, size = 72, style = 'default' } = props

  const rootClassName = cx('block', className, {
    'w-s72 h-s72': size === 72,
    'w-s60 h-s60': size === 60,
    'w-s18 h-s18': size === 18,
  })

  const [ bgColor, arrowColor ] = styleToClasses[style] || styleToClasses.default

  // special view for the small icon
  if (size === 18) {
    return (
      <svg className={rootClassName} width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect className={bgColor} width="18" height="18" rx="9" fill="currentColor" />
        <path className={arrowColor} d="M7 13V5L12 9L7 13Z" fill="currentColor" />
      </svg>
    )
  }

  return (
    <svg className={rootClassName} width={size} height={size} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle className={bgColor} cx="30" cy="30" r="30" fill="currentColor" />
      <path className={arrowColor} d="M21.6665 43.3333V17.5L44.1665 30.4167L21.6665 43.3333Z" fill="currentColor" />
    </svg>
  )
}


export default PlayIcon
