import React, { type MutableRefObject } from 'react'
import cx from 'classnames'

import { Text } from 'components/dataDisplay'

import messages from './messages'


export type MP4Props = {
  className?: string
  url: string
  preview?: string
  autoplay?: boolean
  muted?: boolean
  loop?: boolean
  controls?: boolean
  videoRef?: MutableRefObject<HTMLVideoElement>
  onPlay?: () => void
  onPause?: () => void
  onVideoEnds?: () => void
  onClick?: () => void
}

const MP4: React.FunctionComponent<MP4Props> = (props) => {
  const { className, url, preview, autoplay, muted, loop, controls, videoRef, onPlay, onPause, onVideoEnds, onClick } = props

  return (
    <video
      className={cx(className, 'absolute left-0 top-0 size-full')}
      src={url}
      ref={videoRef}
      poster={preview}
      autoPlay={autoplay}
      playsInline
      muted={muted}
      loop={loop}
      controls={controls}
      onPlay={onPlay}
      onPause={onPause}
      onEnded={onVideoEnds}
      onClick={onClick}
    >
      <Text message={messages.noSupport} style="p3" />
    </video>
  )
}


export default MP4
