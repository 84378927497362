import React from 'react'

import { Text } from 'components/dataDisplay'


type ReadonlyInputProps = {
  className?: string
  label: string | Intl.Message
  value: string | Intl.Message
  'data-testid'?: string
}

const ReadonlyInput: React.FunctionComponent<ReadonlyInputProps> = (props) => {
  const { className, label, value, 'data-testid': dataTestId } = props

  return (
    <div className={className}>
      <Text style="t3" message={label} color="gray-80" />
      <Text style="p2" message={value} data-testid={dataTestId} />
    </div>
  )
}


export default ReadonlyInput
