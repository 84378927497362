import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import { getGlobalHtmlAttrs, type GlobalHTMLAttrs } from 'helpers/getters'

import { Href } from 'components/navigation'
import { AspectContainer } from 'components/layout'

import { Image, Text } from 'components/dataDisplay'
import s from './BrandCard.module.css'


type BrandData = {
  name: string
  to?: string
  logo: string
}

export type BrandCardProps = {
  className?: string
  brand: BrandData
  isActive?: boolean
  onClick?: (data: BrandData) => void
} & GlobalHTMLAttrs

const BrandCard: React.FunctionComponent<BrandCardProps> = (props) => {
  const { className, brand, isActive, onClick, ...rest } = props
  const { name, to, logo } = brand

  const { isMobile } = useDevice()
  const htmlAttrs = getGlobalHtmlAttrs(rest)

  const isClickable = Boolean(to || onClick)

  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick(brand)
    }
  }

  const rootClassName = cx(className, {
    'cursor-pointer': isClickable,
    [s.clickable]: isClickable,
  })

  const cardClassName = cx(s.card, 'border-solid-gray-30 rounded bg-white p-16', {
    [s.activeCard]: isActive,
  })

  return (
    <Href className={rootClassName} to={to} block onClick={handleClick} {...htmlAttrs}>
      <div className={cardClassName}>
        <AspectContainer aspect={144 / 88} fit="contain">
          {
            Boolean(logo) ? (
              <Image className="grayscale" src={logo} alt={name} remWidth={isMobile ? 154 : 144} fill />
            ) : (
              <div />
            )
          }
        </AspectContainer>
      </div>
      <Text className="mt-4 text-center" style="p3">{name}</Text>
    </Href>
  )
}


export default React.memo(BrandCard)
