import React, { useCallback, useEffect, useRef, useState } from 'react'
import Player from '@vimeo/player'
import cx from 'classnames'
import { SilentError } from 'helpers'
import { useDevice } from 'device'

import Preview from '../Preview/Preview'
import { type VideoPreviewIconProps } from '../Preview/VideoPreviewIcon'


export type VimeoPlayerProps = Player.Options & {
  className?: string
  autoplay?: boolean
  onReady?: () => void
  onPlay?: () => void
  onPause?: () => void
  onEnded?: () => void
  onError?: (error: any) => void
  preview?: string
  previewStyle?: VideoPreviewIconProps['style']
  autopause?: boolean
}
const VimeoPlayer: React.FC<VimeoPlayerProps> = (props) => {
  const {
    url,
    autoplay,
    muted,
    loop,
    controls = true,
    autopause,
    onReady,
    onPlay,
    onPause,
    onEnded,
    onError,
    className,
    preview,
    previewStyle,
  } = props

  const { isMobile } = useDevice()
  const [ isVideoVisible, setVideoVisibility ] = useState(!preview)
  // should we start video right after loading
  const autoplayRef = useRef<boolean>(autoplay && !preview)
  // due to restrictions on autoplaying video in mobile browsers, we have to mute the sound for autoplay to work.
  const mutedRef = useRef<boolean>(autoplay && isMobile ? true : muted)

  const containerRef = useRef<HTMLIFrameElement | null>(null)
  const playerRef = useRef<Player | null>(null)

  // all options are passed using src args
  const src = `${url}?muted=${mutedRef.current ? 1 : 0}&controls=${controls ? 1 : 0}&loop=${loop ? 1 : 0}&autopause=${autopause ? 1 : 0}&autoplay=${autoplayRef.current ? 1 : 0}`

  const createPlayer = useCallback(async (): Promise<void> => {
    // we don't use it to create a player, but only to control it
    playerRef.current = new Player(containerRef.current)

    playerRef.current.on('loaded', () => {
      if (typeof onReady === 'function') {
        onReady()
      }
    })

    playerRef.current.on('play', () => {
      if (typeof onPlay === 'function') {
        onPlay()
      }
    })

    playerRef.current.on('pause', () => {
      if (typeof onPause === 'function') {
        onPause()
      }
    })

    playerRef.current.on('ended', () => {
      if (typeof onEnded === 'function') {
        onEnded()
      }
    })

    playerRef.current.on('error', (error) => {
      onError(error)
      throw new SilentError(error)
    })
  }, [ onEnded, onError, onPause, onPlay, onReady ])

  useEffect(() => {
    const isContainerExist = containerRef.current

    if (isContainerExist && isVideoVisible) {
      createPlayer().catch((error) => {
        throw new SilentError(error)
      })
    }

    return () => {
      if (playerRef.current) {
        void playerRef.current.destroy()
        playerRef.current = null
      }
    }
  }, [ createPlayer, isVideoVisible ])

  const handlePreviewClick = () => {
    autoplayRef.current = true
    setVideoVisibility(true)
  }

  return (
    <>
      {
        isVideoVisible ? (
          <iframe
            key={url}
            ref={containerRef}
            className={cx(className, 'absolute left-0 top-0 size-full')}
            src={src}
            frameBorder="0"
            allow="autoplay;encrypted-media;fullscreen;picture-in-picture"
          />
        ) : (
          <Preview
            bgImage={preview}
            previewStyle={previewStyle}
            onClick={handlePreviewClick}
          />
        )
      }
    </>
  )
}

export default VimeoPlayer
