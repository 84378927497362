import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useIntl } from 'intl'
import safeJsonStringify from 'helpers/modifiers/safeJsonStringify'


type MetaProps = {
  title: string | Intl.Message
  metaTitle?: string | Intl.Message
  metaDescription?: string | Intl.Message
  keywords?: string
  image?: string
  pathname?: string
  structuralData?: string
  withoutTitleTemplate?: boolean
}

const multiLineToOneLine = (src: string): string => (
  src.replace(/\n\s*|\s{2,}/mg, ' ').trim()
)

const Meta: React.FunctionComponent<MetaProps> = (props) => {
  const { title, metaTitle, metaDescription, keywords, image, pathname, structuralData, withoutTitleTemplate } = props

  const intl = useIntl()

  const pageTitle = intl.formatMessage(title)
  const pageMetaTitle = metaTitle ? intl.formatMessage(metaTitle) : pageTitle
  const pageMetaDescription = metaDescription ? multiLineToOneLine(intl.formatMessage(metaDescription)) : null

  return (
    <Helmet
      titleTemplate={withoutTitleTemplate ? null : '%s | Scentbird.com'}
    >
      <title>{pageTitle}</title>
      {
        Boolean(pageMetaTitle) && (
          [
            <meta key="title" name="title" content={pageMetaTitle} />,
            <meta key="og:title" property="og:title" content={pageMetaTitle} />,
          ]
        )
      }
      {
        Boolean(pageMetaDescription) && (
          [
            <meta key="description" name="description" content={pageMetaDescription} />,
            <meta key="og:description" property="og:description" content={pageMetaDescription} />,
          ]
        )
      }
      {
        Boolean(keywords) && (
          <meta key="keywords" name="keywords" content={keywords} />
        )
      }
      {
        Boolean(image) && (
          <meta key="og:image" property="og:image" content={image} />
        )
      }
      {
        Boolean(pathname) && (
          <link rel="canonical" href={`https://www.scentbird.com${pathname}`} />
        )
      }
      <meta key="og:type" property="og:type" content="website" />
      {
        Boolean(structuralData) && (
          <script type="application/ld+json">{safeJsonStringify(structuralData)}</script>
        )
      }
    </Helmet>
  )
}


export default React.memo(Meta)
