import React from 'react'
import { twcx } from 'helpers'
import { useDevice } from 'device'
import ImageComponent from '../Image/Image'

import s from './HeroMediaBlock.module.css'


type ImageProps = {
  className?: string
  bgColor?: string
  src: string
  height: number
  alt?: string
  priority?: boolean
  quality?: number
  lazy?: boolean
}

const Image: React.FC<ImageProps> = ({ className, src, height, alt = '', priority, quality, lazy, bgColor = 'bg-light-beige' }) => {
  const style = {
    height: `${height}rem`,
  }

  const { isMobile } = useDevice()

  return (
    <ImageComponent
      className={twcx('block w-full object-cover object-center', bgColor, className)}
      src={src}
      alt={alt}
      sizes={isMobile ? '100vw' : '(min-width: 1440px) 1440px, (min-width: 1080px) 100vw, 1080px'}
      priority={priority}
      lazy={lazy}
      quality={quality}
      style={style}
    />
  )
}

type HeroMediaBlockProps = {
  className?: string
  wideMT?: boolean
  wideMB?: boolean
  title?: string
  'data-testid'?: string
}

type HeroMediaBlockComponent = React.CFC<HeroMediaBlockProps> & {
  Image: React.FunctionComponent<ImageProps>
}

const HeroMediaBlock: HeroMediaBlockComponent = (props) => {
  const { children, className, wideMT = true, wideMB = false, title, 'data-testid': dataTestId } = props

  const rootClassName = twcx('mx-auto max-w-1440', {
    [s.wideMT]: wideMT,
    [s.wideMB]: wideMB,
  }, className)

  return (
    <figure
      className={rootClassName}
      title={title}
      data-testid={dataTestId}
    >
      {children}
    </figure>
  )
}

HeroMediaBlock.Image = Image


export default HeroMediaBlock
