import React from 'react'
import { array, twcx } from 'helpers'

import { Icon, type IconName, Image } from 'components/dataDisplay'

import caseImage from './images/case.png?src-loader'
import caseEmptyImage from './images/case-empty.png?src-loader'
import flaskImage from './images/flask.png?src-loader'


const images = {
  case: caseImage,
  ['case-empty']: caseEmptyImage, // Remove case-empty if CRO-204 ab test fails
  flask: flaskImage,
} as const

type IconKey = 'case-stroke'

const icons: Record<IconKey, IconName> = {
  'case-stroke': 'other/case-stroke',
} as const

export type ProductCountImageProps = {
  className?: string
  imageClassName?: string
  count: number
  imageType: keyof typeof images | IconKey
}

const ProductCountImage: React.FunctionComponent<ProductCountImageProps> = (props) => {
  const { className, imageClassName, count, imageType } = props

  const rootClassName = twcx('flex justify-center', className)
  const finalImageClassName = twcx('w-auto', imageType !== 'case' ? 'mx-4' : 'mx-4 my-8', imageClassName)
  const isIcon = Boolean(icons[imageType])

  return (
    <div className={rootClassName}>
      {
        array.range(1, count).map((_, index) => (
          isIcon ? (
            <Icon
              key={index}
              className={finalImageClassName}
              name={icons[imageType]}
            />
          ) : (
            <Image
              key={index}
              className={finalImageClassName}
              alt={imageType}
              src={images[imageType]}
              remWidth={32}
            />
          )
        ))
      }
    </div>
  )
}


export default ProductCountImage
