import React from 'react'
import cx from 'classnames'

import { Text, Label } from 'components/dataDisplay'
import type { Indent } from '../Label/Label'

import s from './MediaHeadlineBlock.module.css'


export type MediaHeadlineBlockProps = {
  className?: string
  titleStyle: 'h2' | 'h3' | 'h4'
  titleTag?: string
  title: string | Intl.Message
  text?: string | Intl.Message
  label?: string | Intl.Message
  labelBgColor?: 'vinous' | 'black'
  indent?: Indent
}

const MediaHeadlineBlock: React.CFC<MediaHeadlineBlockProps> = (props) => {
  const { className, title, text, label, labelBgColor = 'black', titleStyle, titleTag, indent, children } = props

  const rootClassName = cx(className, s[titleStyle], 'relative max-w-752 text-left', {
    [`pl-${indent}`]: indent,
  })

  return (
    <div className={rootClassName}>
      {
        Boolean(label) && (
          <Label
            className={s.label}
            title={label}
            bgColor={labelBgColor}
            textColor="white"
            indent={indent}
          />
        )
      }
      <Text message={title} style={titleStyle} tag={titleTag} html />
      {
        Boolean(text) && (
          <Text className={titleStyle === 'h2' ? 'mt-16' : 'mt-8'} message={text} style="p2" html />
        )
      }
      {children}
    </div>
  )
}


export default MediaHeadlineBlock
