import React from 'react'
import cx from 'classnames'
import { Icon } from 'components/dataDisplay'


export type CheckboxIconProps = {
  className?: string
  active?: boolean
  disabled?: boolean
  error?: boolean
  style?: 'gold' | 'black'
}

const styles = {
  gold: {
    default: 'bg-gray-10 border-gray-40 hover:border-gold-50',
    active: 'bg-gold-30 border-gold-30 hover:bg-pink hover:border-gold-50',
    disabled: 'bg-gray-10 border-gray-30 hover:border-gray-30',
    error: 'bg-gray-10 border-red',
    iconColor: 'white',
  },
  black: {
    default: 'border-black hover:bg-white',
    active: 'bg-white border-gold-30 hover:border-black',
    disabled: 'border-gray-30 hover:border-gray-30',
    error: 'bg-white border-red',
    iconColor: 'black',
  },
} as const

const CheckboxIcon: React.FunctionComponent<CheckboxIconProps> = (props) => {
  const { className, active, disabled, error, style = 'gold' } = props

  const baseClasses = 'flex h-s24 w-s24 flex-none items-center justify-center rounded border transition-all duration-[230ms]'
  const defaultClasses = styles[style].default
  const activeClasses = styles[style].active
  const disabledClasses = styles[style].disabled
  const errorClasses = styles[style].error

  const rootClassName = cx(baseClasses, className, {
    [`${errorClasses}`]: error,
    [`${activeClasses}`]: active,
    [`${disabledClasses}`]: disabled && !active,
    [`${defaultClasses}`]: !(active || disabled || error),
  },
  disabled ? 'cursor-not-allowed' : 'cursor-pointer'
  )

  return (
    <div className={rootClassName}>
      { active && <Icon name="16/checkmark" color={styles[style].iconColor} /> }
    </div>
  )
}

export default CheckboxIcon
