/* eslint-disable */
import _16_arrow_right from './16/arrow-right.svg'
import _16_arrow_rightSource from './16/arrow-right.svg?raw'
import _16_bubble from './16/bubble.svg'
import _16_bubbleSource from './16/bubble.svg?raw'
import _16_checkmark_small from './16/checkmark-small.svg'
import _16_checkmark_smallSource from './16/checkmark-small.svg?raw'
import _16_checkmark from './16/checkmark.svg'
import _16_checkmarkSource from './16/checkmark.svg?raw'
import _16_close from './16/close.svg'
import _16_closeSource from './16/close.svg?raw'
import _16_dislike from './16/dislike.svg'
import _16_dislikeSource from './16/dislike.svg?raw'
import _16_info from './16/info.svg'
import _16_infoSource from './16/info.svg?raw'
import _16_like from './16/like.svg'
import _16_likeSource from './16/like.svg?raw'
import _20_loader from './20/loader.svg'
import _20_loaderSource from './20/loader.svg?raw'
import _20_star from './20/star.svg'
import _20_starSource from './20/star.svg?raw'
import _24_ai_generated from './24/ai-generated.svg'
import _24_ai_generatedSource from './24/ai-generated.svg?raw'
import _24_attention from './24/attention.svg'
import _24_attentionSource from './24/attention.svg?raw'
import _24_birdie from './24/birdie.svg'
import _24_birdieSource from './24/birdie.svg?raw'
import _24_chain from './24/chain.svg'
import _24_chainSource from './24/chain.svg?raw'
import _24_checkmark_in_circle from './24/checkmark-in-circle.svg'
import _24_checkmark_in_circleSource from './24/checkmark-in-circle.svg?raw'
import _24_checkmark from './24/checkmark.svg'
import _24_checkmarkSource from './24/checkmark.svg?raw'
import _24_close from './24/close.svg'
import _24_closeSource from './24/close.svg?raw'
import _24_drag_simple from './24/drag-simple.svg'
import _24_drag_simpleSource from './24/drag-simple.svg?raw'
import _24_drag from './24/drag.svg'
import _24_dragSource from './24/drag.svg?raw'
import _24_info from './24/info.svg'
import _24_infoSource from './24/info.svg?raw'
import _24_lock from './24/lock.svg'
import _24_lockSource from './24/lock.svg?raw'
import _24_minus from './24/minus.svg'
import _24_minusSource from './24/minus.svg?raw'
import _24_opened_lock from './24/opened-lock.svg'
import _24_opened_lockSource from './24/opened-lock.svg?raw'
import _24_plus_in_circle from './24/plus-in-circle.svg'
import _24_plus_in_circleSource from './24/plus-in-circle.svg?raw'
import _24_plus from './24/plus.svg'
import _24_plusSource from './24/plus.svg?raw'
import _24_question_black from './24/question-black.svg'
import _24_question_blackSource from './24/question-black.svg?raw'
import _24_question from './24/question.svg'
import _24_questionSource from './24/question.svg?raw'
import _32_ai_generated from './32/ai-generated.svg'
import _32_ai_generatedSource from './32/ai-generated.svg?raw'
import _32_amex from './32/amex.svg'
import _32_amexSource from './32/amex.svg?raw'
import _32_arrow_down_in_circle from './32/arrow-down-in-circle.svg'
import _32_arrow_down_in_circleSource from './32/arrow-down-in-circle.svg?raw'
import _32_arrow_down from './32/arrow-down.svg'
import _32_arrow_downSource from './32/arrow-down.svg?raw'
import _32_arrow_left_in_circle from './32/arrow-left-in-circle.svg'
import _32_arrow_left_in_circleSource from './32/arrow-left-in-circle.svg?raw'
import _32_arrow_left from './32/arrow-left.svg'
import _32_arrow_leftSource from './32/arrow-left.svg?raw'
import _32_arrow_right_in_circle from './32/arrow-right-in-circle.svg'
import _32_arrow_right_in_circleSource from './32/arrow-right-in-circle.svg?raw'
import _32_arrow_right from './32/arrow-right.svg'
import _32_arrow_rightSource from './32/arrow-right.svg?raw'
import _32_arrow_up_in_circle from './32/arrow-up-in-circle.svg'
import _32_arrow_up_in_circleSource from './32/arrow-up-in-circle.svg?raw'
import _32_arrow_up from './32/arrow-up.svg'
import _32_arrow_upSource from './32/arrow-up.svg?raw'
import _32_bell from './32/bell.svg'
import _32_bellSource from './32/bell.svg?raw'
import _32_birdie from './32/birdie.svg'
import _32_birdieSource from './32/birdie.svg?raw'
import _32_burger from './32/burger.svg'
import _32_burgerSource from './32/burger.svg?raw'
import _32_cart from './32/cart.svg'
import _32_cartSource from './32/cart.svg?raw'
import _32_close_in_circle from './32/close-in-circle.svg'
import _32_close_in_circleSource from './32/close-in-circle.svg?raw'
import _32_close from './32/close.svg'
import _32_closeSource from './32/close.svg?raw'
import _32_credit_card from './32/credit-card.svg'
import _32_credit_cardSource from './32/credit-card.svg?raw'
import _32_discover from './32/discover.svg'
import _32_discoverSource from './32/discover.svg?raw'
import _32_facebook from './32/facebook.svg'
import _32_facebookSource from './32/facebook.svg?raw'
import _32_filters from './32/filters.svg'
import _32_filtersSource from './32/filters.svg?raw'
import _32_instagram from './32/instagram.svg'
import _32_instagramSource from './32/instagram.svg?raw'
import _32_loader from './32/loader.svg'
import _32_loaderSource from './32/loader.svg?raw'
import _32_lock from './32/lock.svg'
import _32_lockSource from './32/lock.svg?raw'
import _32_maestro from './32/maestro.svg'
import _32_maestroSource from './32/maestro.svg?raw'
import _32_master from './32/master.svg'
import _32_masterSource from './32/master.svg?raw'
import _32_options from './32/options.svg'
import _32_optionsSource from './32/options.svg?raw'
import _32_personalize from './32/personalize.svg'
import _32_personalizeSource from './32/personalize.svg?raw'
import _32_pinterest from './32/pinterest.svg'
import _32_pinterestSource from './32/pinterest.svg?raw'
import _32_question from './32/question.svg'
import _32_questionSource from './32/question.svg?raw'
import _32_queue from './32/queue.svg'
import _32_queueSource from './32/queue.svg?raw'
import _32_scentbird_monogram from './32/scentbird-monogram.svg'
import _32_scentbird_monogramSource from './32/scentbird-monogram.svg?raw'
import _32_search from './32/search.svg'
import _32_searchSource from './32/search.svg?raw'
import _32_shipping from './32/shipping.svg'
import _32_shippingSource from './32/shipping.svg?raw'
import _32_tiktok from './32/tiktok.svg'
import _32_tiktokSource from './32/tiktok.svg?raw'
import _32_twitter from './32/twitter.svg'
import _32_twitterSource from './32/twitter.svg?raw'
import _32_video_gold_arrow from './32/video-gold-arrow.svg'
import _32_video_gold_arrowSource from './32/video-gold-arrow.svg?raw'
import _32_visa from './32/visa.svg'
import _32_visaSource from './32/visa.svg?raw'
import _36_video_white_arrow from './36/video-white-arrow.svg'
import _36_video_white_arrowSource from './36/video-white-arrow.svg?raw'
import _40_amazon_pay from './40/amazon-pay.svg'
import _40_amazon_paySource from './40/amazon-pay.svg?raw'
import _40_apple_pay from './40/apple-pay.svg'
import _40_apple_paySource from './40/apple-pay.svg?raw'
import _40_credit_card_error from './40/credit-card-error.svg'
import _40_credit_card_errorSource from './40/credit-card-error.svg?raw'
import _40_credit_card from './40/credit-card.svg'
import _40_credit_cardSource from './40/credit-card.svg?raw'
import _40_facebook_in_circle from './40/facebook-in-circle.svg'
import _40_facebook_in_circleSource from './40/facebook-in-circle.svg?raw'
import _40_facebook from './40/facebook.svg'
import _40_facebookSource from './40/facebook.svg?raw'
import _40_google_in_circle from './40/google-in-circle.svg'
import _40_google_in_circleSource from './40/google-in-circle.svg?raw'
import _40_google from './40/google.svg'
import _40_googleSource from './40/google.svg?raw'
import _40_messenger from './40/messenger.svg'
import _40_messengerSource from './40/messenger.svg?raw'
import _40_paypal from './40/paypal.svg'
import _40_paypalSource from './40/paypal.svg?raw'
import _40_plus_in_circle from './40/plus-in-circle.svg'
import _40_plus_in_circleSource from './40/plus-in-circle.svg?raw'
import _40_plus from './40/plus.svg'
import _40_plusSource from './40/plus.svg?raw'
import _48_authentic_brands from './48/authentic-brands.svg'
import _48_authentic_brandsSource from './48/authentic-brands.svg?raw'
import _48_bestseller from './48/bestseller.svg'
import _48_bestsellerSource from './48/bestseller.svg?raw'
import _48_cancel_anytime from './48/cancel-anytime.svg'
import _48_cancel_anytimeSource from './48/cancel-anytime.svg?raw'
import _48_charity from './48/charity.svg'
import _48_charitySource from './48/charity.svg?raw'
import _48_clean from './48/clean.svg'
import _48_cleanSource from './48/clean.svg?raw'
import _48_custom_browser from './48/custom-browser.svg'
import _48_custom_browserSource from './48/custom-browser.svg?raw'
import _48_diamond from './48/diamond.svg'
import _48_diamondSource from './48/diamond.svg?raw'
import _48_flexible_plans from './48/flexible-plans.svg'
import _48_flexible_plansSource from './48/flexible-plans.svg?raw'
import _48_limited_time from './48/limited-time.svg'
import _48_limited_timeSource from './48/limited-time.svg?raw'
import _48_new from './48/new.svg'
import _48_newSource from './48/new.svg?raw'
import _48_out_of_stock from './48/out-of-stock.svg'
import _48_out_of_stockSource from './48/out-of-stock.svg?raw'
import _48_scentbird_monogram from './48/scentbird-monogram.svg'
import _48_scentbird_monogramSource from './48/scentbird-monogram.svg?raw'
import _48_shipping from './48/shipping.svg'
import _48_shippingSource from './48/shipping.svg?raw'
import _64_apple_pay from './64/apple-pay.svg'
import _64_apple_paySource from './64/apple-pay.svg?raw'
import _64_credit_card_attention from './64/credit-card-attention.svg'
import _64_credit_card_attentionSource from './64/credit-card-attention.svg?raw'
import _other_amazon_black from './other/amazon-black.svg'
import _other_amazon_blackSource from './other/amazon-black.svg?raw'
import _other_amazon from './other/amazon.svg'
import _other_amazonSource from './other/amazon.svg?raw'
import _other_amex from './other/amex.svg'
import _other_amexSource from './other/amex.svg?raw'
import _other_apple_pay from './other/apple-pay.svg'
import _other_apple_paySource from './other/apple-pay.svg?raw'
import _other_apple_store_dark from './other/apple-store-dark.svg'
import _other_apple_store_darkSource from './other/apple-store-dark.svg?raw'
import _other_apple_store from './other/apple-store.svg'
import _other_apple_storeSource from './other/apple-store.svg?raw'
import _other_case_stroke from './other/case-stroke.svg'
import _other_case_strokeSource from './other/case-stroke.svg?raw'
import _other_discover from './other/discover.svg'
import _other_discoverSource from './other/discover.svg?raw'
import _other_google_play_dark from './other/google-play-dark.svg'
import _other_google_play_darkSource from './other/google-play-dark.svg?raw'
import _other_google_play from './other/google-play.svg'
import _other_google_playSource from './other/google-play.svg?raw'
import _other_join_scentbird from './other/join-scentbird.svg'
import _other_join_scentbirdSource from './other/join-scentbird.svg?raw'
import _other_loader from './other/loader.svg'
import _other_loaderSource from './other/loader.svg?raw'
import _other_long_arrow_left from './other/long-arrow-left.svg'
import _other_long_arrow_leftSource from './other/long-arrow-left.svg?raw'
import _other_long_arrow_right from './other/long-arrow-right.svg'
import _other_long_arrow_rightSource from './other/long-arrow-right.svg?raw'
import _other_master from './other/master.svg'
import _other_masterSource from './other/master.svg?raw'
import _other_no_items from './other/no-items.svg'
import _other_no_itemsSource from './other/no-items.svg?raw'
import _other_paypal_with_logo from './other/paypal-with-logo.svg'
import _other_paypal_with_logoSource from './other/paypal-with-logo.svg?raw'
import _other_paypal from './other/paypal.svg'
import _other_paypalSource from './other/paypal.svg?raw'
import _other_product_with_plus from './other/product-with-plus.svg'
import _other_product_with_plusSource from './other/product-with-plus.svg?raw'
import _other_product from './other/product.svg'
import _other_productSource from './other/product.svg?raw'
import _other_scentbird_logo_medium from './other/scentbird-logo-medium.svg'
import _other_scentbird_logo_mediumSource from './other/scentbird-logo-medium.svg?raw'
import _other_scentbird_logo_small from './other/scentbird-logo-small.svg'
import _other_scentbird_logo_smallSource from './other/scentbird-logo-small.svg?raw'
import _other_scentbird_monogram from './other/scentbird-monogram.svg'
import _other_scentbird_monogramSource from './other/scentbird-monogram.svg?raw'
import _other_shield from './other/shield.svg'
import _other_shieldSource from './other/shield.svg?raw'
import _other_strawberry_shortcake_scentbird_logo from './other/strawberry-shortcake-scentbird-logo.svg'
import _other_strawberry_shortcake_scentbird_logoSource from './other/strawberry-shortcake-scentbird-logo.svg?raw'
import _other_visa from './other/visa.svg'
import _other_visaSource from './other/visa.svg?raw'
const icons = {
  '16/arrow-right': { src: _16_arrow_right, source: __SERVER__ && _16_arrow_rightSource, width: 16, height: 16, aspect: 1 }, 
  '16/bubble': { src: _16_bubble, source: __SERVER__ && _16_bubbleSource, width: 16, height: 16, aspect: 1 }, 
  '16/checkmark-small': { src: _16_checkmark_small, source: __SERVER__ && _16_checkmark_smallSource, width: 16, height: 16, aspect: 1 }, 
  '16/checkmark': { src: _16_checkmark, source: __SERVER__ && _16_checkmarkSource, width: 16, height: 16, aspect: 1 }, 
  '16/close': { src: _16_close, source: __SERVER__ && _16_closeSource, width: 16, height: 16, aspect: 1 }, 
  '16/dislike': { src: _16_dislike, source: __SERVER__ && _16_dislikeSource, width: 16, height: 16, aspect: 1 }, 
  '16/info': { src: _16_info, source: __SERVER__ && _16_infoSource, width: 16, height: 16, aspect: 1 }, 
  '16/like': { src: _16_like, source: __SERVER__ && _16_likeSource, width: 16, height: 16, aspect: 1 }, 
  '20/loader': { src: _20_loader, source: __SERVER__ && _20_loaderSource, width: 20, height: 20, aspect: 1 }, 
  '20/star': { src: _20_star, source: __SERVER__ && _20_starSource, width: 20, height: 20, aspect: 1 }, 
  '24/ai-generated': { src: _24_ai_generated, source: __SERVER__ && _24_ai_generatedSource, width: 24, height: 24, aspect: 1 }, 
  '24/attention': { src: _24_attention, source: __SERVER__ && _24_attentionSource, width: 24, height: 24, aspect: 1 }, 
  '24/birdie': { src: _24_birdie, source: __SERVER__ && _24_birdieSource, width: 24, height: 24, aspect: 1 }, 
  '24/chain': { src: _24_chain, source: __SERVER__ && _24_chainSource, width: 24, height: 24, aspect: 1 }, 
  '24/checkmark-in-circle': { src: _24_checkmark_in_circle, source: __SERVER__ && _24_checkmark_in_circleSource, width: 24, height: 24, aspect: 1 }, 
  '24/checkmark': { src: _24_checkmark, source: __SERVER__ && _24_checkmarkSource, width: 24, height: 24, aspect: 1 }, 
  '24/close': { src: _24_close, source: __SERVER__ && _24_closeSource, width: 24, height: 24, aspect: 1 }, 
  '24/drag-simple': { src: _24_drag_simple, source: __SERVER__ && _24_drag_simpleSource, width: 24, height: 24, aspect: 1 }, 
  '24/drag': { src: _24_drag, source: __SERVER__ && _24_dragSource, width: 24, height: 24, aspect: 1 }, 
  '24/info': { src: _24_info, source: __SERVER__ && _24_infoSource, width: 24, height: 24, aspect: 1 }, 
  '24/lock': { src: _24_lock, source: __SERVER__ && _24_lockSource, width: 24, height: 24, aspect: 1 }, 
  '24/minus': { src: _24_minus, source: __SERVER__ && _24_minusSource, width: 24, height: 24, aspect: 1 }, 
  '24/opened-lock': { src: _24_opened_lock, source: __SERVER__ && _24_opened_lockSource, width: 24, height: 24, aspect: 1 }, 
  '24/plus-in-circle': { src: _24_plus_in_circle, source: __SERVER__ && _24_plus_in_circleSource, width: 24, height: 24, aspect: 1 }, 
  '24/plus': { src: _24_plus, source: __SERVER__ && _24_plusSource, width: 24, height: 24, aspect: 1 }, 
  '24/question-black': { src: _24_question_black, source: __SERVER__ && _24_question_blackSource, width: 24, height: 24, aspect: 1 }, 
  '24/question': { src: _24_question, source: __SERVER__ && _24_questionSource, width: 24, height: 24, aspect: 1 }, 
  '32/ai-generated': { src: _32_ai_generated, source: __SERVER__ && _32_ai_generatedSource, width: 32, height: 32, aspect: 1 }, 
  '32/amex': { src: _32_amex, source: __SERVER__ && _32_amexSource, width: 32, height: 32, aspect: 1 }, 
  '32/arrow-down-in-circle': { src: _32_arrow_down_in_circle, source: __SERVER__ && _32_arrow_down_in_circleSource, width: 32, height: 32, aspect: 1 }, 
  '32/arrow-down': { src: _32_arrow_down, source: __SERVER__ && _32_arrow_downSource, width: 32, height: 32, aspect: 1 }, 
  '32/arrow-left-in-circle': { src: _32_arrow_left_in_circle, source: __SERVER__ && _32_arrow_left_in_circleSource, width: 32, height: 32, aspect: 1 }, 
  '32/arrow-left': { src: _32_arrow_left, source: __SERVER__ && _32_arrow_leftSource, width: 32, height: 32, aspect: 1 }, 
  '32/arrow-right-in-circle': { src: _32_arrow_right_in_circle, source: __SERVER__ && _32_arrow_right_in_circleSource, width: 32, height: 32, aspect: 1 }, 
  '32/arrow-right': { src: _32_arrow_right, source: __SERVER__ && _32_arrow_rightSource, width: 32, height: 32, aspect: 1 }, 
  '32/arrow-up-in-circle': { src: _32_arrow_up_in_circle, source: __SERVER__ && _32_arrow_up_in_circleSource, width: 32, height: 32, aspect: 1 }, 
  '32/arrow-up': { src: _32_arrow_up, source: __SERVER__ && _32_arrow_upSource, width: 32, height: 32, aspect: 1 }, 
  '32/bell': { src: _32_bell, source: __SERVER__ && _32_bellSource, width: 32, height: 32, aspect: 1 }, 
  '32/birdie': { src: _32_birdie, source: __SERVER__ && _32_birdieSource, width: 32, height: 32, aspect: 1 }, 
  '32/burger': { src: _32_burger, source: __SERVER__ && _32_burgerSource, width: 32, height: 32, aspect: 1 }, 
  '32/cart': { src: _32_cart, source: __SERVER__ && _32_cartSource, width: 32, height: 32, aspect: 1 }, 
  '32/close-in-circle': { src: _32_close_in_circle, source: __SERVER__ && _32_close_in_circleSource, width: 32, height: 32, aspect: 1 }, 
  '32/close': { src: _32_close, source: __SERVER__ && _32_closeSource, width: 32, height: 32, aspect: 1 }, 
  '32/credit-card': { src: _32_credit_card, source: __SERVER__ && _32_credit_cardSource, width: 32, height: 32, aspect: 1 }, 
  '32/discover': { src: _32_discover, source: __SERVER__ && _32_discoverSource, width: 30, height: 6, aspect: 5 }, 
  '32/facebook': { src: _32_facebook, source: __SERVER__ && _32_facebookSource, width: 32, height: 32, aspect: 1 }, 
  '32/filters': { src: _32_filters, source: __SERVER__ && _32_filtersSource, width: 32, height: 32, aspect: 1 }, 
  '32/instagram': { src: _32_instagram, source: __SERVER__ && _32_instagramSource, width: 32, height: 32, aspect: 1 }, 
  '32/loader': { src: _32_loader, source: __SERVER__ && _32_loaderSource, width: 32, height: 32, aspect: 1 }, 
  '32/lock': { src: _32_lock, source: __SERVER__ && _32_lockSource, width: 32, height: 32, aspect: 1 }, 
  '32/maestro': { src: _32_maestro, source: __SERVER__ && _32_maestroSource, width: 29, height: 18, aspect: 1.6111111111111112 }, 
  '32/master': { src: _32_master, source: __SERVER__ && _32_masterSource, width: 29, height: 18, aspect: 1.6111111111111112 }, 
  '32/options': { src: _32_options, source: __SERVER__ && _32_optionsSource, width: 32, height: 32, aspect: 1 }, 
  '32/personalize': { src: _32_personalize, source: __SERVER__ && _32_personalizeSource, width: 32, height: 32, aspect: 1 }, 
  '32/pinterest': { src: _32_pinterest, source: __SERVER__ && _32_pinterestSource, width: 32, height: 32, aspect: 1 }, 
  '32/question': { src: _32_question, source: __SERVER__ && _32_questionSource, width: 32, height: 32, aspect: 1 }, 
  '32/queue': { src: _32_queue, source: __SERVER__ && _32_queueSource, width: 32, height: 32, aspect: 1 }, 
  '32/scentbird-monogram': { src: _32_scentbird_monogram, source: __SERVER__ && _32_scentbird_monogramSource, width: 30, height: 28, aspect: 1.0714285714285714 }, 
  '32/search': { src: _32_search, source: __SERVER__ && _32_searchSource, width: 32, height: 32, aspect: 1 }, 
  '32/shipping': { src: _32_shipping, source: __SERVER__ && _32_shippingSource, width: 32, height: 32, aspect: 1 }, 
  '32/tiktok': { src: _32_tiktok, source: __SERVER__ && _32_tiktokSource, width: 32, height: 32, aspect: 1 }, 
  '32/twitter': { src: _32_twitter, source: __SERVER__ && _32_twitterSource, width: 32, height: 32, aspect: 1 }, 
  '32/video-gold-arrow': { src: _32_video_gold_arrow, source: __SERVER__ && _32_video_gold_arrowSource, width: 32, height: 32, aspect: 1 }, 
  '32/visa': { src: _32_visa, source: __SERVER__ && _32_visaSource, width: 32, height: 32, aspect: 1 }, 
  '36/video-white-arrow': { src: _36_video_white_arrow, source: __SERVER__ && _36_video_white_arrowSource, width: 36, height: 36, aspect: 1 }, 
  '40/amazon-pay': { src: _40_amazon_pay, source: __SERVER__ && _40_amazon_paySource, width: 40, height: 40, aspect: 1 }, 
  '40/apple-pay': { src: _40_apple_pay, source: __SERVER__ && _40_apple_paySource, width: 40, height: 40, aspect: 1 }, 
  '40/credit-card-error': { src: _40_credit_card_error, source: __SERVER__ && _40_credit_card_errorSource, width: 40, height: 40, aspect: 1 }, 
  '40/credit-card': { src: _40_credit_card, source: __SERVER__ && _40_credit_cardSource, width: 40, height: 40, aspect: 1 }, 
  '40/facebook-in-circle': { src: _40_facebook_in_circle, source: __SERVER__ && _40_facebook_in_circleSource, width: 40, height: 40, aspect: 1 }, 
  '40/facebook': { src: _40_facebook, source: __SERVER__ && _40_facebookSource, width: 40, height: 40, aspect: 1 }, 
  '40/google-in-circle': { src: _40_google_in_circle, source: __SERVER__ && _40_google_in_circleSource, width: 40, height: 40, aspect: 1 }, 
  '40/google': { src: _40_google, source: __SERVER__ && _40_googleSource, width: 40, height: 40, aspect: 1 }, 
  '40/messenger': { src: _40_messenger, source: __SERVER__ && _40_messengerSource, width: 40, height: 40, aspect: 1 }, 
  '40/paypal': { src: _40_paypal, source: __SERVER__ && _40_paypalSource, width: 40, height: 40, aspect: 1 }, 
  '40/plus-in-circle': { src: _40_plus_in_circle, source: __SERVER__ && _40_plus_in_circleSource, width: 40, height: 40, aspect: 1 }, 
  '40/plus': { src: _40_plus, source: __SERVER__ && _40_plusSource, width: 40, height: 40, aspect: 1 }, 
  '48/authentic-brands': { src: _48_authentic_brands, source: __SERVER__ && _48_authentic_brandsSource, width: 48, height: 48, aspect: 1 }, 
  '48/bestseller': { src: _48_bestseller, source: __SERVER__ && _48_bestsellerSource, width: 48, height: 48, aspect: 1 }, 
  '48/cancel-anytime': { src: _48_cancel_anytime, source: __SERVER__ && _48_cancel_anytimeSource, width: 48, height: 48, aspect: 1 }, 
  '48/charity': { src: _48_charity, source: __SERVER__ && _48_charitySource, width: 48, height: 48, aspect: 1 }, 
  '48/clean': { src: _48_clean, source: __SERVER__ && _48_cleanSource, width: 48, height: 48, aspect: 1 }, 
  '48/custom-browser': { src: _48_custom_browser, source: __SERVER__ && _48_custom_browserSource, width: 50, height: 50, aspect: 1 }, 
  '48/diamond': { src: _48_diamond, source: __SERVER__ && _48_diamondSource, width: 48, height: 48, aspect: 1 }, 
  '48/flexible-plans': { src: _48_flexible_plans, source: __SERVER__ && _48_flexible_plansSource, width: 48, height: 48, aspect: 1 }, 
  '48/limited-time': { src: _48_limited_time, source: __SERVER__ && _48_limited_timeSource, width: 48, height: 48, aspect: 1 }, 
  '48/new': { src: _48_new, source: __SERVER__ && _48_newSource, width: 48, height: 48, aspect: 1 }, 
  '48/out-of-stock': { src: _48_out_of_stock, source: __SERVER__ && _48_out_of_stockSource, width: 48, height: 48, aspect: 1 }, 
  '48/scentbird-monogram': { src: _48_scentbird_monogram, source: __SERVER__ && _48_scentbird_monogramSource, width: 48, height: 48, aspect: 1 }, 
  '48/shipping': { src: _48_shipping, source: __SERVER__ && _48_shippingSource, width: 48, height: 48, aspect: 1 }, 
  '64/apple-pay': { src: _64_apple_pay, source: __SERVER__ && _64_apple_paySource, width: 64, height: 34, aspect: 1.8823529411764706 }, 
  '64/credit-card-attention': { src: _64_credit_card_attention, source: __SERVER__ && _64_credit_card_attentionSource, width: 64, height: 64, aspect: 1 }, 
  'other/amazon-black': { src: _other_amazon_black, source: __SERVER__ && _other_amazon_blackSource, width: 103, height: 20, aspect: 5.15 }, 
  'other/amazon': { src: _other_amazon, source: __SERVER__ && _other_amazonSource, width: 132, height: 40, aspect: 3.3 }, 
  'other/amex': { src: _other_amex, source: __SERVER__ && _other_amexSource, width: 76, height: 40, aspect: 1.9 }, 
  'other/apple-pay': { src: _other_apple_pay, source: __SERVER__ && _other_apple_paySource, width: 95, height: 50, aspect: 1.9 }, 
  'other/apple-store-dark': { src: _other_apple_store_dark, source: __SERVER__ && _other_apple_store_darkSource, width: 168, height: 53, aspect: 3.169811320754717 }, 
  'other/apple-store': { src: _other_apple_store, source: __SERVER__ && _other_apple_storeSource, width: 168, height: 53, aspect: 3.169811320754717 }, 
  'other/case-stroke': { src: _other_case_stroke, source: __SERVER__ && _other_case_strokeSource, width: 11, height: 44, aspect: 0.25 }, 
  'other/discover': { src: _other_discover, source: __SERVER__ && _other_discoverSource, width: 76, height: 40, aspect: 1.9 }, 
  'other/google-play-dark': { src: _other_google_play_dark, source: __SERVER__ && _other_google_play_darkSource, width: 168, height: 53, aspect: 3.169811320754717 }, 
  'other/google-play': { src: _other_google_play, source: __SERVER__ && _other_google_playSource, width: 168, height: 53, aspect: 3.169811320754717 }, 
  'other/join-scentbird': { src: _other_join_scentbird, source: __SERVER__ && _other_join_scentbirdSource, width: 402, height: 62, aspect: 6.483870967741935 }, 
  'other/loader': { src: _other_loader, source: __SERVER__ && _other_loaderSource, width: 20, height: 20, aspect: 1 }, 
  'other/long-arrow-left': { src: _other_long_arrow_left, source: __SERVER__ && _other_long_arrow_leftSource, width: 41, height: 16, aspect: 2.5625 }, 
  'other/long-arrow-right': { src: _other_long_arrow_right, source: __SERVER__ && _other_long_arrow_rightSource, width: 40, height: 16, aspect: 2.5 }, 
  'other/master': { src: _other_master, source: __SERVER__ && _other_masterSource, width: 76, height: 40, aspect: 1.9 }, 
  'other/no-items': { src: _other_no_items, source: __SERVER__ && _other_no_itemsSource, width: 146, height: 168, aspect: 0.8690476190476191 }, 
  'other/paypal-with-logo': { src: _other_paypal_with_logo, source: __SERVER__ && _other_paypal_with_logoSource, width: 94, height: 24, aspect: 3.9166666666666665 }, 
  'other/paypal': { src: _other_paypal, source: __SERVER__ && _other_paypalSource, width: 95, height: 26, aspect: 3.6538461538461537 }, 
  'other/product-with-plus': { src: _other_product_with_plus, source: __SERVER__ && _other_product_with_plusSource, width: 120, height: 120, aspect: 1 }, 
  'other/product': { src: _other_product, source: __SERVER__ && _other_productSource, width: 384, height: 384, aspect: 1 }, 
  'other/scentbird-logo-medium': { src: _other_scentbird_logo_medium, source: __SERVER__ && _other_scentbird_logo_mediumSource, width: 144, height: 32, aspect: 4.5 }, 
  'other/scentbird-logo-small': { src: _other_scentbird_logo_small, source: __SERVER__ && _other_scentbird_logo_smallSource, width: 134, height: 32, aspect: 4.1875 }, 
  'other/scentbird-monogram': { src: _other_scentbird_monogram, source: __SERVER__ && _other_scentbird_monogramSource, width: 77, height: 71, aspect: 1.0845070422535212 }, 
  'other/shield': { src: _other_shield, source: __SERVER__ && _other_shieldSource, width: 28, height: 32, aspect: 0.875 }, 
  'other/strawberry-shortcake-scentbird-logo': { src: _other_strawberry_shortcake_scentbird_logo, source: __SERVER__ && _other_strawberry_shortcake_scentbird_logoSource, width: 336, height: 44, aspect: 7.636363636363637 }, 
  'other/visa': { src: _other_visa, source: __SERVER__ && _other_visaSource, width: 76, height: 40, aspect: 1.9 }
} as const
export type IconName = keyof typeof icons
export default icons