import React from 'react'
import cx from 'classnames'

import { Icon, Text, type TextStyle } from 'components/dataDisplay'


type CheckedListProps = {
  className?: string
  items: Array<string | Intl.Message>
  gap?: 0 | 4 | 8 | 16 |24
  checkmarkGap?: 8 | 16
  withCheckmark?: boolean
  textStyle?: TextStyle
  'data-testid'?: string
}

const gapToMarginClassName: Record<CheckedListProps['gap'], string> = {
  0: '',
  4: 'mt-4',
  8: 'mt-8',
  16: 'mt-16',
  24: 'mt-24',
}
const checkmarkGapToMarginClassName: Record<CheckedListProps['checkmarkGap'], string> = {
  8: 'mr-8',
  16: 'mr-16',
}

const CheckedList: React.FunctionComponent<CheckedListProps> = (props) => {
  const { className, items, gap = 24, checkmarkGap = 16, withCheckmark = true, textStyle = 'p2', 'data-testid': dataTestId } = props

  return (
    <div className={className} role="list" data-testid={dataTestId}>
      {
        items.map((item, index) => (
          <div key={index} className={cx('flex items-start', index > 0 && gapToMarginClassName[gap])} role="listitem">
            {
              withCheckmark && (
                <Icon className={cx('flex-none', checkmarkGapToMarginClassName[checkmarkGap])} name="24/checkmark" color="gold-50" />
              )
            }
            <Text className="pt-1" message={item} style={textStyle} html />
          </div>
        ))
      }
    </div>
  )
}


export default CheckedList
