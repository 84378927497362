import { useEffect, useRef } from 'react'


type Props = {
  activeRating: number
}

const useRatingKeyboard = ({ activeRating }: Props) => {
  const ratingContainerRef = useRef<HTMLDivElement>()
  const activeIndex = useRef(activeRating)

  activeIndex.current = activeRating - 1

  useEffect(() => {
    const stars = Array.from(ratingContainerRef.current?.children || [])

    if (stars.length) {
      const handleKeyDown = (event) => {
        const isUp = [ 39, 38 ].includes(event.keyCode)
        const isDown = [ 37, 40 ].includes(event.keyCode)
        const isRatingChange = isUp || isDown

        if (isRatingChange) {
          const nextIndex = isUp ? activeIndex.current + 1 : activeIndex.current - 1
          const activeStar = Array.from(ratingContainerRef.current?.children || [])[nextIndex] as HTMLElement

          activeStar?.focus?.()
        }
      }

      stars.forEach((star) => star.addEventListener('keydown', handleKeyDown))

      return () => {
        stars.forEach((star) => star.removeEventListener('keydown', handleKeyDown))
      }
    }
  }, [])

  return ratingContainerRef
}


export default useRatingKeyboard
