import React from 'react'

import { RadioIcon, Text } from 'components/dataDisplay'
import { RadioGroup } from 'components/inputs'
import type { RadioOptionChildProps } from 'components/inputs'


type OptionProps = {
  label: Intl.Message | string
} & RadioOptionChildProps

const RadioOptionSimpleView: React.FunctionComponent<OptionProps> = (props) => {
  const { label, checked, disabled } = props

  return (
    <div className="flex items-start py-12 text-left">
      <RadioIcon active={checked} />
      <RadioGroup.Label
        as={Text}
        className="w-full pl-16"
        message={label}
        tag="label"
        style="p2"
        color={disabled ? 'gray-80' : 'black'}
        html
      />
    </div>
  )
}


export default RadioOptionSimpleView
